.csv-main {
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: left;
}

.csv-master {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}