.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.alignCenter {
    align-items: center;
}

#no-marg {
    margin: 0;
}

.max-window {
    width: 100%;
    height: 100%;
}

.text-input-standard {
    border: none;
    color: #8b8280;
    background-color: rgb(248, 248, 248);
    border: solid #8b8280 1px;
    border-radius: 4px;
    padding: 4px;
    padding-left: 12px;
    margin-bottom: 2px;
    text-align: left;
    font-weight: 600;
    width: 140px;
    box-sizing: border-box;
    /* width: 100%; */
}
.text-input-select {
    border: none;
    color: #8b8280;
    background-color: rgb(248, 248, 248);
    border: solid #8b8280 1px;
    border-radius: 4px;
    padding: 4px;
    padding-left: 6px;
    margin-bottom: 2px;
    text-align: left;
    font-weight: 600;
    width: 140px;
    box-sizing: border-box;
}

/* .text-input-standard:hover {
    background-color: rgb(240, 234, 234);
} */

/* .text-input-standard:focus {
    border: none;
    border-bottom: solid black 1px;
    outline: none;
} */

input, button, select {
    font-family: 'Roboto', sans-serif;
}
select {
    padding: 0;
    margin: 0;
}
.text-left {
    text-align: left;
}

.hoverShading:hover {
    background-color: rgb(255, 255, 255);
    cursor: default;
}

.pad-horz-20 {
    box-sizing: border-box;
    padding: 10px 30px;
}

.horz-30 {
    box-sizing: border-box;
    padding: 0px 30px;
}

#engagedDropdown {
    background-color: #f4f4ef;
}

#engagedCompetitorTab {
    font-weight: 500;
}

#warning-popup-no-name {
    border: solid rgb(255, 207, 207) 1px;
    font-size: 14px;
    padding: 0px 8px;
}

.warning-popup-small-div {
    border: solid rgb(255, 120, 120) 1px;
    text-align: left;
    padding: 0px 8px;
    font-size: 12px;
    border-left: none;
    padding-left: 0px;
    margin-bottom: 7px;
    width: fit-content;
}

/* .border-basic {
    border: solid black 1px;
} */

.nav-link-btn {
    margin: 0;
    font-size: 12px;
    font-weight: 800;
    width: fit-content;
    padding: 5px 20px;
    color: #8b8280;
    border-radius: 3px;
    border: solid 2px #8b8280;
    background-color: transparent;
}

.nav-link-btn:hover {
    border-color: #c3890b;
    /* color: #ffe0bc; */
}

.svg-icon {
    width: 22px;
}

.table-geo-tabs {
    width: fit-content;
}

.geo-btn-tbl {
    position: relative;
    top: 1px;
   padding: 4px 18px;
   font-size: 12px;
   border-top-right-radius: 5px;
   border-top-left-radius: 5px;
   margin-right: 2px;
   background-color: transparent;
   border: solid #8b8280 1px;
   color: #8b8280;
   font-weight: 500;
   /* border: solid white 1px; */
   border-bottom: none;
}

.geo-btn-tbl:hover {
    cursor: default;
}

#curr-geo-btn {
    background-color: white;
    border-color: #8b8280;
    border-bottom-color: white;
    color: black;
    opacity: 1;
}

.export-report-list {
    margin: 0;
    padding: 10px 0px;
    padding-left: 15px;
}

.export-report-head {
    margin: 0px;
    margin-top: 15px;
}

.export-loader {
    width: 30px;
    margin-left: 10px;
}

.export-success {
    width: 22px;
    margin-left: 10px;
    filter: invert(28%) sepia(44%) saturate(882%) hue-rotate(71deg) brightness(92%) contrast(92%);
}

.export-error {
    font-size: 12px;
    font-weight: 600;
    color: rgb(175, 0, 0);
    margin-top: 0px;
}