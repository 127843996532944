.InputSidebar-main {
    justify-content: left;
    /* background-color: #f0f0f0; */
    height: 100%;
    width: 290px;
    /* box-sizing: border-box; */
    /* padding: 0px 20px; */
    color: #2a2e54;
    overflow-y: auto;
}

.Bancroft-title {
    background-color: #3c8ab6;
    text-align: left;
}

.banc-title-sub {
    display: grid;
    grid-template-columns: 1fr 50px;
}


.close-sb-icon {
    margin-top: 20px;
    margin-right: 5px;
    width: 32px;
    transform: rotate(90deg);
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(131deg) brightness(105%) contrast(105%);
}
.close-sb-icon:hover {
    filter: invert(57%) sepia(81%) saturate(673%) hue-rotate(347deg) brightness(99%) contrast(94%);
}

#Bancroft-title {
    font-size: 30px;
    letter-spacing: -1px;
    color: #ffffff;
    padding-left: 30px;
}

.basic-hr {
    background-color: black;
    height: 2px;
    margin: 30px 30px;
    margin-top: 37px;
}

.modal-hr {
    background-color: black;
    height: 1px;
    margin-top: 35px;
    margin-bottom: 5px;
}

/* #new-forecast {

} */
.input-and-label-standard {
    width: 100%;
}

.border-bottom {
    
    margin-bottom: 10px;
    border-bottom: solid black 1px;
}

/* #explicit-width-input {
    width: 140px;
    box-sizing: border-box;
} */

#new-forecast-header {
    font-size: 20px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 15px;
    color: #8b8280;
}

#prior-forecast-header {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
}

#new-forecast-text-input {
    /* width: 100%; */
    margin-bottom: 12px;
    color: rgb(85, 163, 85);
    font-weight: 700;
    padding-left: 8px;
    width: 100%;
}

#label-drugname {
    font-size: 13px;
    font-weight: 700;
    margin: 0px;
    margin-bottom: 3px;
}

#forecast-name-shown-div {
    background-color: #64ab37;
    padding-left: 30px;
    padding-top: 2px;
}
#forecast-name-shown {
    color: white;
    font-weight: 600;
    padding: 9px 0px;
    margin: 0px;
}
#forecast-name-label {
    font-size: 20px;
    font-weight: 500;
    padding: 15px 0px;
    margin: 0px;
    color: white;
}
#main-geo-selector {
    border: none;
    background-color: transparent;
    color: white;
    font-size: 20px;
    font-weight: 500;
    padding: 15px 0px;
    margin: 0px;
}
#main-geo-selector:focus {
    outline: none;
}
#forecast-name-label-div {
    padding-left: 30px;
    background-color: #969de3;
}

#calculate-text {
    margin-top: 22px;
}

#calc-icon-x {
    width: 13px;
    margin-left: auto;
    margin-right: 3px;
    filter: invert(100%) sepia(94%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
}

#calc-icon-x:hover {
    filter: none;
}

.input-header{
    margin-bottom: 4px;
    margin-top: 10px;
    font-weight: 500;
    color: #8b8280;
}

#p-text-x {
    margin-top: 0;
}

#year-input-q {
    width: 83px;
}

.forecast-header {
    margin: 5px 0px;
    padding: 5px 20px;
    font-size: 19px;
}

.inputs-container {
    text-align: left;
    box-sizing: border-box;
    width: 100%;
    /* margin: 10px 0px; */
    /* padding: 0px 20px; */
    padding-bottom: 15px;
    background-color: transparent;
}

.input-action-btn {
    margin-top: 6px;
    font-size: 12px;
    font-weight: 800;
    width: fit-content;
    padding: 5px 20px;
    color: white;
    border-radius: 3px;
    border:none;
    background-color: #c3890b;
}

.icon-and-header{
    display: flex;
    flex-direction: row;
    color: #818181;
    align-items: center;
    font-size: 16px;
    /* font-weight: 300; */
}
#carot-icon-sb {
    width: 24px;
    filter: invert(55%) sepia(5%) saturate(21%) hue-rotate(319deg) brightness(91%) contrast(88%);
    margin-left: auto;
}
#carot-icon-sb:hover {
    filter: invert(53%) sepia(59%) saturate(425%) hue-rotate(157deg) brightness(82%) contrast(100%);
}
#carot-icon-sb-flipped:hover {
    filter: invert(53%) sepia(59%) saturate(425%) hue-rotate(157deg) brightness(82%) contrast(100%);
}
#carot-icon-sb-flipped {
    width: 24px;
    filter: invert(55%) sepia(5%) saturate(21%) hue-rotate(319deg) brightness(91%) contrast(88%);
    margin-left: auto;
    transform: rotate(180deg);
}

#engaged-btn-sb {
    color: #3c8ab6;
}

#engaged-btn-sb-geo {
    color: #3c8ab6;
    min-height: 54px;
}

#engaged-icon-sb {
    filter: invert(53%) sepia(59%) saturate(425%) hue-rotate(157deg) brightness(82%) contrast(100%);
}
#geography-dropdown {
    background-color: rgb(62, 57, 57);
    border: none;
    padding: 0px 30px;
    box-sizing: border-box;
    min-height: 54px;
    text-align: left;
}
#engaged-geography-dropdown {
    background-color: rgb(25, 23, 23);
    border: none;
    padding: 0px 30px;
    box-sizing: border-box;
    min-height: 54px;
    text-align: left;
}
#geo-text-sb {
    padding: 18px 0;
    font-size: 15px;
}
#geography-input-outer {
    margin: 0;
}


.add-geo-text {
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
    opacity: .6;
    cursor: pointer;
}

.container-icon {
    width: 25px;
    margin-right: 8px;
    filter: invert(55%) sepia(5%) saturate(21%) hue-rotate(319deg) brightness(91%) contrast(88%);    /* margin-bottom: 2px; */
    /* height: 25px; */
}

.container-document-icon {
    width: 18px;
    margin-right: 12px;
    filter: invert(55%) sepia(5%) saturate(21%) hue-rotate(319deg) brightness(91%) contrast(88%);
}

.new-f-icon {
    width: 30px;
    margin-right: 8px;
    margin-bottom: 10px;
}

.general-btn {
    margin-top: 30px;
    margin-bottom: 10px;
}

/* .prior-forecasts-main {
    margin-top: 30px;
} */

.prior-forecast-btn {
    padding: 7px 0px;
    padding-bottom: 0px;
    margin: 0px;
}

/* .prior-forecast-btn:hover {
    background-color: rgb(255, 255, 255);
} */

.prior-forecasts-grouping {
    padding-left: 15px;
    font-weight: 500;
}

.example-forecast-main {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

#example-forecast-btn {
    border-bottom:  solid black 1px;
}

.competitor-drug-panel-outer {
    border-bottom: solid black 2px;
    flex-wrap: wrap;
}

.competitor-drug-panel-item {
    background-color: white;
    border-bottom: solid rgb(226, 226, 226) 1px;
    margin: 0;
    color: black;
}

.competitor-drug-panel-item:hover {
    background-color: rgb(247, 247, 247);
}

.competitor-drug-text {
    font-size: 13px;
    margin: 0;
    padding: 8px 0px;
}

#competitor-tiny-btn {
    font-size: 25px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    line-height: 0;
    margin-left: auto;
    margin-right: 7px;
    margin-top: 15px;
}

#competitor-tiny-btn-plus {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    line-height: 0;
    margin-left: auto;
    margin-right: 7px;
    margin-top: 15px;
}

.competitor-inputs-body {
    padding-bottom: 15px;
}

#competitor-btn-bottom {
    margin-left: 30px;
}

#competitor-btn-bottom-remove {
    margin-top: 25px;
    margin-left: 30px;
    margin-bottom: 20px;
    background-color: rgb(153, 153, 153);
}

.collapsing-container {
    /* margin-top: 15px; */
    /* margin: 0px; */
    padding: 0px;
    width: 100%;
}

.input-section {
    margin-top: 0px;
    padding-top: 0px;
}

.input-header-geography {
    font-weight: 400;
    color: black;
    margin-bottom: 10px;
    text-align: left;
}

.collapsing-container-btn {
    width: 100%;
    margin: 0px;
    padding: 16px 30px;
    background-color: #ffffff;
    border-top: solid 1px rgb(212, 212, 212);
    /* border-bottom: solid 1px rgb(212, 212, 212); */
    /* line-height: 40px; */
}

.collapsing-container-btn-text {
    margin: 0px;
    padding: 0px;
    font-weight: 500;
    font-size: 14px;
}

.active-section-background {
    background-color: rgb(255, 255, 255);
    padding-bottom: 20px;
    color: rgb(165, 165, 165);
}

#bottom-box {
    padding-bottom: 12px;
}

#forecast-name-text {
    font-weight: 400;
}

#quarter-selector {
    width: fit-content;
    margin-right: 10px;
}

.quarter-div-inputs {
    width: 80%;
}

#numerator-ratio {
    width: 60px;
    height: fit-content;
    padding-left: 4px;
}
#prev-deci {
    padding-left: 4px;
}
#denominator-ratio {
    width: 120px;
    height: fit-content;
    padding-left: 4px;
}

#ratio-slash {
    padding: 0px 6px;
    padding-top: 4px;
    margin: 0px;
    /* margin: 0px 3px; */
    font-weight: 600;
}


/* pateint calculator */

.patient-calculator-outer {
    padding: 20px;
    padding-top: 0px;
    background-color: white;
    border: solid gray 1px;
    position: absolute;
    z-index: 10;
    left: 20%;
    top: 10%;
    /* width: 300px; */
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
    color: black;
}

.disease-excerpt-name {
    margin: 0px;
    margin-top: 8px;
    padding: 0px;
    font-weight: 500;
    font-size: 14px;
}

.disease-excerpt-text {
    margin: 0px;
    margin-top: 4px;
    font-size: 13px;
}

#interested {
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
}

.p-calc-main .input-header{
    font-weight: 300;
}

.faded-div {
    opacity: .7;
    -webkit-mask-image: linear-gradient(to right, black 50%, transparent 100%);
    mask-image: linear-gradient(to right, black 50%, transparent 100%);}
.faded-div:hover {
    opacity: 1;
    -webkit-mask-image: none;
    mask-image: none;
    cursor: pointer;
}

.calc-by-div {
    align-items: center;
}

#calculate-text {
    font-size: 16px;
    margin-right: 10px;
}

#calculate-selector {
    width: fit-content;
    margin: 0;
    margin-top: 14px;
    border: none;
    font-weight: 500;
    border-radius: 0px;
    padding-right: 5px;
    border-bottom: solid black 1px;
}

#calculate-selector:focus {
    outline: none;
}

#patients-p-c-text {
    margin: 0;
    margin-top: 25px;
    padding: 0;
    font-weight: 500;
}

.p-calc-geo-name {
    font-weight: 400;
    margin-top: 5px;
    opacity: .6;
    font-size: 14px;
    margin-bottom: 0;
}

.p-calc-geo-val {
    font-size: 14px;
    font-weight: 400;
}

.p-c-t-d {
    margin: 0;
    margin-top: 20px;
    padding: 7px;
    background-color: #3c8ab6;
    align-items: center;
}

.p-calc-title {
    margin: 0;
    /* padding: 7px; */
    text-align: center;
    color: white;
}

#or-text {
    margin: 5px 0px;
}

#geography-text {
    margin-right: 10px;
}

#alter-geo-label {
    font-size: 12px;
    margin: 0px;
}
#alter-geos {
    margin-top: 10px;
    padding: 0;
}
.geo-p-val {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}
.geo-name-plain {
    margin: 5px 0;
    margin-top: 0px;
    font-size: 12px;
    color: #8b8280;
}

#input-select-geo {
    margin-top: 10px;
    margin-bottom: 0;
}

.p-calc-btn {
    color: #c3890b;
    font-size: 13px;
}


.calc-pat-div {
    align-items: center;
    cursor: default;
}

.calc-pat-div:hover {
    opacity: .9;
    cursor: pointer;
}

.orange-triangle {
    margin-right: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 8px;
    border-color: transparent transparent transparent #c3890b;
}


.add-geo {
    color: rgb(95, 185, 90);
    font-size: 20px;
}

.geo-remove {
    color: rgb(185, 90, 90);
    font-size: 20px;
}

.hover-pointer:hover {
    cursor: pointer;
}

#geo-plus {
    margin: 0;
    margin-bottom: 5px;
    color: rgb(78, 78, 78);
}

#add-geo-btn {
    margin: 0;
    padding: 6px 12px;
    margin-bottom: 1px;
}

#no-geo-warning {
    color: rgb(242, 113, 113);
    font-size: 12px;
}

#geo-2-txt {
    font-size: 14px;
    margin: 1px 0px;
}

.bottom-sidebar {
    margin-top: auto;
    padding:0px 30px;
}

.copyright {
    font-size: 12px;
    padding-bottom: 15px;
    margin: 0;
    margin-top: auto;
}

.geo-sb-text {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    color: #6e6e6e;
    text-align: left;
    
}
#geo-sb-text-engaged {
    font-weight: 500;
}

.fixed-sidebar-items {
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 5;
}

.unchecked-geo {
    margin: 0;
    padding: 0;
    width: 11px;
    height: 11px;
    margin-right: 8px;
    background-color: white;
    border: solid #6e6e6e 1px;
    border-radius: 2px;
}

.geo-div-xyz {
    align-items: center;
    margin-bottom: 5px;
}

.check-geo-sb {
    width: 13px;
    margin-right: 8px;
    filter: invert(61%) sepia(49%) saturate(915%) hue-rotate(346deg) brightness(99%) contrast(95%);
}
.x-geo-sb {
    margin: 0;
    padding: 0;
    margin-left: auto;
    width: 12px;
    margin-right: 7px;
    filter: invert(59%) sepia(0%) saturate(1155%) hue-rotate(150deg) brightness(91%) contrast(90%);
}
#input-select-geo-nav {
    height: fit-content;
    margin-right: 8px;
}

/* geography warning overlay */

.geo-warning {
    background-color: #cfcfcf;
    z-index: 100;
    font-size: 14px;
    text-align: left;
    transform: translate(50%, 0%);
    padding: 10px 15px;
}

#geo-cancel-btn {
    margin-left: 8px;
    background-color: rgb(62, 62, 62);
    /* color: rgb(44, 44, 44); */
}

.warning-txt-geo {
    color: rgb(204, 30, 30);
    font-size: 15px;
    margin: 0;
    margin-top: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}