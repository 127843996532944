.data-page-master {
    padding: 40px;
    overflow-y: scroll;
}

.dp-table-master {
    margin-top: 20px;
}

.dp-th-drug {
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding: 0;
}

.dp-th-pharmacies {
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding: 0;
}

.dp-tb-drugs-div {
    margin: 0;
    width: fit-content;
}

.dp-tb-drugs {
    height: 40px;
    border-bottom: solid black 1px;
}

.dp-tb-pharmacies {
    margin: 0;
}

.dp-tb-pharmacy {
    border-bottom: solid 1px black;
    height: 40px;
    line-height: 40px;
}

.dp-tb-pharmacies-txt {
    margin: 0;
    padding: 0px 10px;
    border-right: solid 1px rgb(230, 230, 230);
}