.prevalence-page-master {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f4f4ef;
}

.pp-banc-title-div {
    margin-left: 60px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


#pp-title {
    margin: 0;
    padding: 0;
}
.pp-subtitle {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #c3890b;
}

.bb-box {
    box-sizing: border-box;
}

.pp-geo-active-btn {
    border: solid rgb(47, 47, 47) 1px;
    border-radius: 4px;
    padding: 5px 12px;
    padding-right: 2px;
    margin-right: 16px;
}

.pp-geog-title {
    margin-right: 20px;
    font-size: 27px;
}

.pp-geo-active-btn-txt {
    margin: 0;
    opacity: .5;
    font-size: 14px;
    font-weight: 500;
}

#calc-icon-x-iso {
    width: 8px;
    margin-left: 12px;
    opacity: .6;
}

#calc-icon-x-iso-2 {
    width: 10px;
    margin-left: 18px;
    opacity: .5;
    margin-bottom: 1px;
}

#calc-icon-x-iso-2:hover {
    opacity: 1;
    cursor: pointer;
}

.pp-geo-active-btn:hover {
    cursor: pointer;
    background-color: rgb(248, 248, 248);
    #calc-icon-x-iso {
        opacity: 1;
    }
}

.pp-active-geo-d {
    margin: 15px 0;
    margin-bottom: 30px;
}

#add-geo-btn-iso {
    margin: 0;
    margin-top: 4px;
    margin-left: 10px;
    padding: 6px 20px;
}

#input-select-geo-nav-iso {
    min-width: 200px;
}

#pp-nav {
    margin-left: 0px;
    width: 100%;
}

#pp-calculator {
    width: fit-content;
    min-width: 600px;
    color: black;
    text-align: left;
    position: relative;
}

.pp-body {
    width: 100%;
    height: 100%;
    margin-top: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.pp-geo-txt {
    margin-right: 8px;
    margin-top: 20px;
    font-weight: 500;
    opacity: .4;
}

#pp-input-select-geo-nav {
    height: fit-content;
    margin-right: 8px;
    width: fit-content;
    padding-right: 5px;
}

.dev-divv {
    width: 200px;
    height: 200px;
    background-color: red;
    margin-left: auto;
    margin-right: auto;
}

#pp-d-search {
    width: 85%;
    margin-bottom: 10px;
}

.pp-d-subtext {
    color: #3c8ab6;
    font-weight: 600;
    font-size: 15px;
}

.pp-disease-section {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    padding-right: 35px;
    margin-bottom: 20px;
    padding-bottom: 120px;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    /* height: 100vh; */
}

.pp-excerpts-holder {
    box-sizing: border-box;
}

.pp-calc-holder {
    padding: 15px;
    margin-top: 22px;
}

.pp-disease-header {
    font-weight: 500;
    font-size: 15px;
}

#iso-disease-name {
    font-weight: 700;
}

#iso-disease-txt {
    line-height: 18px;
}

.pp-excerpts-hr {
    width: 100%;
    height: 1px;
    background-color: gray;
    margin-top: 30px;
    margin-bottom: 15px;
    z-index: 100;
}

.pp-d-link-txt {
    font-weight: 800;
    padding: 0;
    margin: 8px 0;
}

.pp-d-link-txt:hover {
    cursor: pointer;
    color: #c3890b;
    text-decoration: underline;
}

/* calculator mods */

#iso-title-txt {
    font-size: 22px;
    font-weight: 500;
    padding: 3px 7px;
}

#iso-patients-p-c-text {
    color: #3c8ab6;
    font-size: 21px;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
}

#iso-p-calc-geo {
    /* background-color: white;
    padding: 15px; */
    margin-top: 30px;
}

.iso-max-geos {
    font-size: 20px;
    margin: 0;
    padding: 0;
    margin-top: 4px;
    font-weight: 500;
    color: #c3890b;
}

#iso-p-calc-geo-name {
    font-weight: 700;
    font-size: 19px;
    color: black;
    opacity: 1;
}

#iso-calculate-text {
    margin-top: 20px;
    color: black;
    font-weight: 400;
    opacity: .7;
}

.iso-calc-header {
    font-weight: 400;
    opacity: .7;
    margin-bottom: 0;
}

#iso-or-text {
    margin: 0px 9px;
    color: black;
    opacity: .7;
    margin-top: 3px;
    font-weight: 400;
}

#iso-ratio-slash {
    font-weight: 300;
    margin: 0px 12px;
    margin-top: 5px;
}

#iso-calc-type-selector {
    width: 200px;
}

.iso-calc-format {
    position: absolute;
    right: 0;
    z-index: 5;
}

#iso-calc-format-selector {
    margin-left: 10px;
    width: fit-content;
    padding: 2px;
}

.iso-res-prev {
    font-weight: 400;
    opacity: .6;
    font-size: 17px;
    margin-bottom: 0;
}

.iso-res-prev-num {
    margin: 0;
    font-size: 18px;
    margin-top: 5px;
    font-weight: 500;
}