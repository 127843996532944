.chat-master {
    padding: 20px;
    display: flex;
    justify-content: center;
}

.chat-box {
    align-items: center;
    justify-content: center;
    width: 60vw;
    height: 80vh;
}

.chat-res {
    border: solid gray 1px;
    overflow-y: auto;
    padding: 20px;
    min-height: 400px;
    width: 100%;
    box-sizing: border-box;
}

.chat-req {
    width: 100%;
    margin-top: 10px;
}

.chat-tb {
    width: 100%;
    box-sizing: border-box;
}