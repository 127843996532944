.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}
.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: #2196F3;
}
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}

/* Additional CSS for form styling */
label, select, input, textarea {
    display: block;
    margin-top: 10px;
}
fieldset {
    text-align: left;
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 20px;
}
legend {
    font-weight: bold;
}
#geographies_note {
    font-size: 12px;
    margin-top: 4px;
}

textarea {
    resize:vertical;
    min-height: 20px;
    padding: 3px;
}

/* new sidebar */

.qa-app-div {
    padding-bottom: 20px;
    margin: 30px;
    margin-bottom: 10px;
    border-bottom: solid black 1px;
}

.qa-page-results {
    display: flex;
}

.qa-res-section {
    text-align: left;
}

.sales-section {
    margin-right: 100px;
}

.qa-notes-section {
    margin-top: 20px;
    padding-top: 20px;
    border-top: solid rgb(187, 187, 187) 2px;
    padding-bottom: 20px;
}

.qa-notes-inner {
    justify-content: flex-start;
}

.qa-notes-small {
    text-align: left;
    display: flex;
    /* justify-content: center;
    align-items: center; */
}

.qa-notes-margin {
    margin-right: 34px;
}

.qa-notes-small-inputs {
    text-align: left;
    margin-left: 40px;
}

.qa-notes-left {
    display: flex;
    flex-direction: column;
    text-align: left;
}

#notes {
    height: 90%;
}

.qa-sales-header {
    color: #c3890b;
    font-size: 17px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 0;
}
.qa-sales-val {
    color: black;
    margin: 0;
    margin-top: 10px;
    font-size: 17px;
    font-weight: 500;
}

/* graphs */

.qa-bar-graph-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    margin-right: 35px;
}

.qa-bar-graph-main {
    display: flex;
    flex-direction: row;
}
.qa-bar-graph-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.qa-bar-graph-val {
    margin: 0;
    padding: 0;
}
.qa-bar-graph-outline {
    width: 90px;
    height: 90px;
    padding: 0 8px;
    display: flex;
    
    padding-top: 12px;
    flex-direction: row;
    border-bottom: solid rgb(114, 114, 114) 1px;
}
.qa-bar-graph-graph {
    /* padding: 15px 0; */
    padding-top: 12px;
    width: 90px;
    text-align: center;
    color: white;
    margin-top: auto;
}
.qa-bar-graph-header {
    font-size: 18px;
}
.qa-bar-graph-label {
    font-size: 13px;
}

.qa-graph-curr-stage {
    margin: 10px 0;
    font-size: 16px;
    margin-bottom: 7px;
}

.bar-graph-lower {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qa-curr-stage-val {
    margin: 0;
    padding: 0;
    font-size: 16px;
}

.qa-input-header {
    margin: 0;
    font-weight: 500;
}

.qa-notes-top-spacer {
    margin-top: 16px;
}