.scenarios-container {
    position: absolute;
    margin: 0;
    padding: 10px;
    top: 90px;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 12;
    border: solid 1px gray;
    border-radius: 5px;
}

.scenarios-header {
    color: #3c8ab6;
}

.scenario-item-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.scenario-item {
    font-size: 16px;
    margin: 0;
    padding: 0;
}

.scenario-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-left: auto;
}

.scenario-edit-btn {
    margin: 0;

    margin-right: 4px;
    color: #8b8280;
    padding: 0;
}

.scenario-copy-btn {
    margin: 0;
    margin-left: 4px;
    color: #8b8280;
    padding: 0;
}

.scenario-btn:hover {
    color: black;
    cursor: pointer;
}

.or-detail {
    margin: 0;
    font-weight: 900;
    font-size: 18px;
    padding: 0;
}

.scenarios-inputs {
    width: 120px;
}

.scenario-copy-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
}

.scenario-select {
    padding: 0;
    margin: 0 10px;
    height: fit-content;
}

.scenario-copy-submit {
    color: white;
    margin: 0;
    padding: 5px 10px;
}