.table-container-outer {
    overflow-x: auto;
    padding-bottom: 20px;
    padding-top: 5px;
    margin-left: 20px;
    /* justify-content: center; */
    text-align: left;
    position: relative;
}


.table-container-border {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    padding-top: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    z-index: 3;
    /* width: 100%; */

}



.extra-padding-right {
    padding-right: 20px;
    background-color: white;
    border-radius: 5px;
    border-top-left-radius: 0px;
    padding-bottom: 5px;
    border: solid 1px #8b8280;
}

.border-bot {
    border-bottom: solid 1px #004881;
}

.dash-border {
    border-right: dashed 1px rgb(167, 167, 167);
}

.border-top {
    border-top: solid 1px #004881;
}

.dark-border {
    border-bottom: solid #004881 1px;
}

/* .table-geo-tabs {

} */

.table-keys-container {
    position: sticky;
    left: 0;
    background-color: #3c8ab6;
    z-index: 30;
    color: white;
    width: fit-content;
}

#no-bg {
    background-color: white;
}

.table-data-container {
    /* keep track of this 162px value this may be a bad route */
    /* margin-left: 165px; */
    background-color: white;
    z-index: 3;
    position: relative;
}

.m-share-key-btn {
    padding-top: 4px;
    margin-top: 5px;
    margin-left: 12px;
}

.carot-icon {
    width: 13px;
}
.carot-icon:hover {
    cursor: pointer;
    filter: invert(84%) sepia(21%) saturate(453%) hue-rotate(170deg) brightness(91%) contrast(97%);
}
.mskbflipped {
    transform: rotate(180deg);
}
#m-share-break {
    margin-top: 0px;
}
#m-share-wrap-text {
    white-space: wrap;
    word-wrap: break-word;
    max-width: 150px;
}

.msbb {
    box-sizing: border-box;
    white-space: nowrap;
    padding: 3px 12px;
    padding-right: 6px;
    /* height: fit-content; */
}

.table-header-basic {
    min-width: 115px;
    /* max-width: 115px;  */
}

#even-bg {
    background-color: #ebf3f8;
}
.even-bg {
    background-color: #ebf3f8;
}

.table-header-div {
    width: 100%;
}

.table-header-top-left {
    /* padding: 3px 5px; */
    font-size: 15px;
    height: 30px;
    width: 150px;
    min-width: 150px;
}

.table-key-basic {
    padding: 3px 15px;
    font-size: 14px;
    height: 30px;
    width: 118px;
}

#pre-launch-col {
    background-color: #ebf3f8;
    
}

.spacer-pl-expenses {
    margin-top: auto;
    margin-bottom: 42px;
}

#tbl-input-field {
    padding: 2px 4px;
    width: 80%;
    font-size: 11px;
}

#pencil-edit-tbl {
    width: 13px;
    margin-left: auto;
    margin-right: 5px;
    filter: invert(51%) sepia(4%) saturate(616%) hue-rotate(324deg) brightness(101%) contrast(84%);
}

#pencil-edit-tbl:hover {
    filter: invert(55%) sepia(7%) saturate(3653%) hue-rotate(158deg) brightness(87%) contrast(86%);
}

.table-totals-tab {
    margin-left: auto;
    margin-right: 20px;
}

.tbl-expenses-txt {
    padding: 0px 15px;
    color: #686565;
    margin-bottom: 6px;
}

#expenses-input {
    margin: 0 15px;
    width: 80%;
}

#pre-launch-date {
    background-color: white;
}

#pre-launch-div {
    background-color: white;
    color: #3c8ab6;
}

#pre-launch-plus {
    font-size: 16px;
    margin-left: 7px;
    margin-right: 6px;
    color: #f6921e;
    cursor: pointer;
}

#pre-launch-minus {
    font-size: 22px;
    color: #f6921e;
    cursor: pointer;
}
#pre-launch-minus:hover{
    opacity: .7;

}
#pre-launch-plus:hover {
    opacity: .7;
}

.table-key-text {
    padding: 0;
    margin: 0;
    line-height: 30px;
    font-weight: 500;

}

.table-header-basic {
    padding: 3px 12px;
    padding-right: 6px;
    font-size: 15px;
    height: 30px;
    /* width: 150px; */
}

.table-header-text {
    padding: 0;
    margin: 0;
    line-height: 30px;
    /* padding-left: 10px; */
}

#table-name {
    font-weight: 900;
    font-size: 20px;
}

.table-col-div {
    box-sizing: border-box;
    width: fit-content;
    position: relative;
}


.single-edit-field {
    font-size: 12px;
    height: 30px;
    min-width: 115px;
    /* width: fit-content; */
    padding: 3px 12px;
    padding-right: 6px;

}

.single-edit-field-text {
    padding: 0;
    margin: 0;
    line-height: 30px;
    /* padding-left: 10px; */
}


.expand-text {
    margin: 0px;
    padding: 0px;
}

#market-share-key {
    padding: 3px 15px;
    font-size: 14px;
    height: 30px;
    width: 118px;
}

.market-share-data-div {
    padding: 0;
    margin: 4px 0;
    /* line-height: 30px; */
    /* padding-left: 15px; */
    font-size: 12px;
    /* height: fit-content; */
    /* width: fit-content; */
}

.market-share-data {
    padding: 0;
    margin: 0;
    line-height: 30px;
    /* width: auto; */
    /* padding-left: 10px; */
}

.market-share-data-x {
    padding: 0;
    margin: 0;
}

#m-share-collapsed {
    width: auto;
    white-space: nowrap;
}

#m-share-collapsed:hover {
    background-color: #a9cee4;
    cursor: pointer;
}

#market-share-data {
    /* padding: 4px 0; */
}

.forecast-event-blurb {
    z-index: 10;
    transform: translate(-70%, -90%);
    padding: 8px 14px;
    background-color: #f6921e;
    font-size: 12px;
    color: white;
    border-radius: 10px;
    border: solid white 1px;
    align-items: center;
    pointer-events: none;
}

.forecast-event-blurb-text {
    margin: 3px 0;
    padding: 0;
}

.forecast-event-icon {
    margin-left: 8px;
    margin-top: 5px;
    /* margin-bottom: 2px; */
}

.blurb-svg {
    margin-top: 4px;
    margin-right: 6px;
}

