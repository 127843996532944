
.sum-sb-pad {
    padding: 0px 30px;
}

.summary-main {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 290px 1fr;
}

.summary-content {
    background-color: rgb(77, 75, 75);
    overflow: auto;
    padding: 25px;
    padding-top: 20px;
}

.sum-basic-container {
    background-color: white;
    border-radius: 3px;
    width: 100%;
}

#as-icon-top {
    opacity: 1;
    filter: invert(99%) sepia(5%) saturate(2%) hue-rotate(232deg) brightness(113%) contrast(100%);
}

.as-folder-top {
    cursor: default;
    padding: 0;
    margin: 0;
    margin-left: 12px;
    align-items: center;
}

.as-folder-top-txt {
    color: white;
    margin: 0;
    padding: 0;
    margin-left: 5px;
    border-bottom: solid 1px white;
}

/* folder view  */
.folder-icon-big {
    width: 25px;
    margin-right: 10px;
}


.folder-view-master {
    margin-top: 7px;
    padding: 30px;
    width: 80%;
    box-sizing: border-box;
    padding-bottom: 100px;
    padding-top: 10px;
}

.folder-name-div-txt {
    font-size: 20px;
    color: #5fc3d7;
}

.fv-file {
    align-items: center;
    border-bottom: solid rgb(197, 197, 197) 1px;
    padding: 10px 15px;
    padding-left: 25px;
    background-color: rgb(248, 248, 248);
}

.fv-file-x {
    cursor: default;
    align-items: center;
    width: 100%;
    border-bottom: solid rgb(197, 197, 197) 1px;
    padding: 10px 15px;
    padding-left: 25px;
    background-color: rgb(248, 248, 248);
    box-sizing: border-box;
}

.sum-sb-new-folder-name {
    text-align: left;
    margin: 0;
    margin-left: 30px;
    color: #000;
}

.fv-file-clickable {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.fv-file-content {
    text-align: left;
}

.fv-no-docs {
    text-align: left;
    font-size: 15px;
    padding-left: 25px;
}

.fv-file-name {
    opacity: .7;
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-weight: 400;
}

.fv-file-date {
    opacity: .7;
    margin: 0;
    padding: 0;
    margin-top: 1px;
    font-size: 11px;
    font-weight: 300;
}
.fv-file-date-val {
    font-weight: 500;
}

.fv-bt {
    padding: 15px 0;
    padding-left: 25px;
    border-top: solid rgb(197, 197, 197) 1px;
    background-color: white;
}

.fv-tool-txt {
    cursor: default;
    margin: 0;
    padding: 0;
    margin-left: 5px;
    margin-right: 25px;
    font-weight: 400;
    opacity: .7;
}

.fv-file-unchecked {
    margin: 0;
    padding: 0;
    width: 9px;
    height: 9px;
    margin-right: 25px;
    background-color: white;
    border: solid #a3a3a3 2px;
    border-radius: 2px;
}

.fv-rel {
    position: relative;
}

.fv-modal {
    position: absolute;
    transform: translate(0%, 20%);
    min-width: 170px;
    height: fit-content;
    border: solid #c0c0c0 1px;
    border-top: none;
    border-radius: 1px;
    background-color: white;
    z-index: 20;
}

.folder-opts-modal {
    right: 0;
    top: 0%;
    transform: translate(0%, 25%);
    border: solid #c0c0c0 1px;
    padding: 20px;
    padding-top: 10px;
    display: flex;
    text-align: left;
}

#opts-btn-r {
    margin-top: 10px;
}

.del-modal {
    border-top: solid #c0c0c0 1px;
    transform: translate(0%, 25%);
    text-align: left;
    padding: 20px;
}

.fv-del-modal-txt {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.fv-delete {
    background-color: red;
    margin-top: 15px;
}

.fvdx {
    margin-top: 5px;
}

.fv-modal-item {
    cursor: default;
    border-top: solid #c0c0c0 1px;
    padding: 8px 10px;
    padding-right: 30px;
}
.fv-modal-item:hover {
    background-color: rgb(229, 229, 229);
}
.fv-modal-txt {
    font-weight: 400;
    color: #878787;
    margin: 0;
    padding: 0;
    margin-left: 10px;
    font-size: 12px;
}

.mi-add-folder {
    cursor: default;
    border-top: solid #c0c0c0 1px;
    padding: 8px 10px;
    padding-right: 30px;
    background-color: #f6921e;
    color: white;
}

.mi-txt {
    color: white;
}

.share-link-txt {
    padding: 8px;
    background-color: rgb(227, 227, 227);
}

/* summary page  */


.gpt-sum-and-submit {
    display: grid;
    grid-template-columns: 1fr 1px 330px;
    margin-bottom: 35px;
    margin-top: 7px;
}

.generate-summary-btn {
    height: fit-content;
    padding: 15px 0;
    margin: 0;
    margin-top: auto;
    background-color: #f6921e;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 500;
    color: white;
    border: solid white 2px;
    border-radius: 3px;
}

.save-to-txt {
    margin: 0;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
}
#sum-save-select {
    width: 85%;
    margin-bottom: 7px;
    font-weight: 400;
}
#sum-save-select-y {
    width: 105px;
    font-weight: 400;
    margin: 0;
    margin-left: 10px;
    height: 26px;
}
#gen-submit {
    margin-top: 15px;
}

#url-action-btn {
    border: solid white 1px;
    margin: 0;
    margin-left: 5px;
    padding: 5px 9px;
}

#url-action-btn:hover {
    background-color: #ff8800;
}

.sum-save-options {
    margin-top: 0px;
    padding: 7px;
    background-color: #5fc3d7;
    align-items: center;
    color: white;
    text-align: center;
}

#sum-name-txt {
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400;
}

.gpt-summary-title {
    font-size: 19px;
    font-weight: 500;
    text-align: left;
    margin: 0;
    margin-bottom: 25px;
}

.ss-d {
    margin-top: 10px;
    /* justify-content: space-between; */
}

#download-sum-save-btn{
    margin-left: 20px;
}

#save-btn-sg {
    padding: 5px 37px;
    margin-bottom: 8px;
    margin-top: 10px;
}

#regen-sum-btn {
    align-self: flex-start;
    margin-top: 13px;
    background-color: rgb(134, 134, 134);
    color: white;
    padding: 5px 16px;
}

.txt-border-options {
    width: 1px;
    margin: 20px 0;
    background-color: gray;
}

.gpt-submit {
    background-color: #ffffff;
    text-align: left;
    padding: 40px;
    padding-top: 30px;
    box-sizing: border-box;
}

.welcome-sum {
    text-align: left;
}

.welcome-sum-title {
    color: #5fc3d7;
    font-size: 20px;
}

.welcome-sum-subtitle {
    font-size: 14px;
    font-weight: 400;
}

.gpt-summary-div {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    padding: 30px;
    box-sizing: border-box;
}

.as-user-inputs {
    background-color: #f6921e;
    padding: 10px 15px;
    color: white;
    align-items: center;
}

.as-input-type {
    align-items: center;
    padding: 0;
}

.save-sum-success {
    color: #1ab93a;
    font-weight: 500;
    font-size: 13px;
    margin: 0;
    margin-left: 8px;
}

.as-input-txt {
    margin: 0;
    padding: 0;
    margin-right: 10px;

}

input[type="file"]::file-selector-button {
    font-family: "Roboto";
    border-radius: 4px;
    padding: 5px 8px;
    border: none;
    cursor: pointer;
    background-color: white;
    font-size: 12px;
    color: rgb(126, 126, 126);
    margin-right: 10px;
}

.summary-textarea {
    resize: none;
    width: 100%;
    height: 500px;
    max-height: 2000px;
    border: none;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
}
.summary-textarea:focus {
    outline: none;
}

/* sidebar */
.summary-sidebar {
    justify-content: left;
    height: 100%;
    width: 290px;
    color: #2a2e54;
    overflow-y: auto;
}

.sum-hr {
    margin: 10px 30px;
    height: 2px;
    background-color: rgb(185, 185, 185);
}

.opts-hr {
    margin: 15px 0;
    height: 1px;
    background-color: rgb(185, 185, 185);
}

.file-sum-sb {
    align-items: center;
    padding: 8px 30px;
}

.sum-sb-file-txt {
    margin: 0;
    padding: 0;
    font-size: 13px;
    margin-left: 8px;
    text-align: left;
}

.file-sum-sb:hover {
    background-color: rgb(243, 243, 243);
}

.new-folder-btn {
    margin-top: 15px;
    cursor: default;
}

.sum-sb-new {
    text-align: left;
    padding: 20px 30px;
    padding-top: 10px;
}

.sum-sb-search-div {
    align-items: center;
}

.sum-sb-sort {
    margin-left: auto;
}

.sum-sb-h5 {
    text-align: left;
    color: #000;
    margin: 8px 0;
    font-size: 13px;
    font-weight: 500;
}

#sum-sb-search {
    /* padding: 10px; */
    height: 22px;
    font-weight: 400;
}

#sum-sb-search-x {
    height: 22px;
    margin: 0;
    margin-left: 30px;
    margin-top: 5px;
    font-weight: 400;
}

.user-summary-inputs {
    display: flex;
    flex-direction: column;
    width: 400px;
}


.gpt-text-output {
    text-align: left;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 0;
}

#last-para {
    margin-bottom: 30px;
}


/* icons  */

.folder-icon-menu {
    margin-left: auto;
    margin-right: 15px;
    width: 15px;
    opacity: .5;
}

.folder-icon-menu:hover {
    opacity: 1;
}

.sum-sb-caret {
    filter: brightness(0) saturate(100%);
    width: 11px;
    margin-left: auto;
    transform: rotate(-90deg);
}

.folder-icon {
    width: 15px;
    opacity: .5;
}
.doc-icon {
    opacity: .5;
    width: 13px;
    margin-right: 1px;
}
.sort-icon {
    width: 12px;
}

.sort-txt {
    margin: 0;
    padding: 0;
    color: #878787;
    font-size: 14px;
    margin-left: 4px;
}

.open-folder-icon {
    width: 15px;
    opacity: .5;

}

.fv-checkbox {
    width: 13px;
    margin-right: 25px;
    filter: invert(61%) sepia(49%) saturate(915%) hue-rotate(346deg) brightness(99%) contrast(95%);
}


/* colorls */

.sum-hover-color {
    opacity: 1;
    color: #5fc3d7;
}

#sum-icon-hover {
    filter: invert(69%) sepia(79%) saturate(281%) hue-rotate(144deg) brightness(90%) contrast(86%);
    opacity: 1;
}

#sum-add-color {
    filter: invert(67%) sepia(46%) saturate(2562%) hue-rotate(346deg) brightness(98%) contrast(96%);
    opacity: 1;
}

#sum-add-txt {
    color: #f6921e
}

#fv-bg-hover {
    background-color: white;
}

#mi-icon-color {
    opacity: 1;
    filter: brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(0%) hue-rotate(53deg) brightness(104%) contrast(104%);
}