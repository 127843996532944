.login-page-master {
    background-color: #f4f4ef;
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.log-banc-div {
    margin-left: 50px;
    align-self: flex-start;
    font-size: 20px;
}

.login-header {
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}

.login-subtext {
    opacity: .4;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    margin-bottom: 6px;
}

.login-div {
    text-align: left;
    background-color: white;
    border-radius: 5px;
    width: 40%;
    align-self: center;
    padding: 40px 60px;
}

.forgot-password {
    color: #3c8ab6;
    font-size: 10px;
    font-weight: 700;
    margin-top: 7px;
}

.successfulReset-click {
    color: #1f6f9d;
    font-weight: 900;
    cursor: pointer;
    text-decoration: underline;
}

.forgot-password:hover {
    text-decoration: underline;
    cursor: pointer;
}

.no-acc-txt {
    color: rgb(144, 144, 144);
    margin-top: 28px;
}

.show-hide-password {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: #6195b3;
}

.form-type-change {
    cursor: pointer;
    font-weight: 600;
    color: #3c8ab6;
}


#login-btn {
    background-color: #3c8ab6;
    color: white;
    /* padding: 20px; */
    margin-top: 20px;
    
    font-size: 16px;
    padding: 6px 30px;
}

#login-input {
    width: 80%;
    padding: 3px 4px;
    margin: 0;
}

.extra-pad {
    margin-top: 22px;
}