
.App {
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: "Roboto", sans-serif;
}

body {
    overflow: hidden;
}

/* non boilerplate css  */


.master-container {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 290px 1fr;
}

.master-container-x {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns:1fr;
}

.content-container {
    overflow: auto;
    padding: 25px;
    padding-top: 20px;
    background-color: #f4f4ef;
}

.nav-elems {
    display: flex;
    flex-direction: row;
    padding-right: 30px;
    margin-left: auto;
    height: fit-content;
    align-items: center;
}

.nav-elem {
    margin-left: 14px;
}

.nav-elem-text {
    font-size: 13px;
    margin-left: 4px;
    font-weight: 500;
    color: #8b8280;
}

.navBar-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.nav-forecast-title {
    font-size: 25px;
    width: fit-content;
    font-weight: 300;
    padding: 0;
    padding-top: 5px;
    margin: 0;
    color: rgb(0, 0, 0);
}

.nav-forecast-title-name {
    color: #3c8ab6;
    font-weight: 600;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #ffffff; /* Gutter color */
    border: none;
}

::-webkit-scrollbar-thumb {
    background: rgb(222, 222, 222); /* Scroll handle color */
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(133, 133, 133); /* Scroll handle color */
}
  
.open-sb-icon {
    width: 40px;
    margin-bottom: 10px;
    transform: rotate(-90deg);
    /* filter: invert(98%) sepia(2%) saturate(9%) hue-rotate(29deg) brightness(102%) contrast(104%); */
}

.open-sb-icon:hover {
    filter: invert(61%) sepia(49%) saturate(915%) hue-rotate(346deg) brightness(99%) contrast(95%);
}


#nav-notepad .svg-icon {
    width: 25px;
}

#finance-icon {
    width: 19px;
    margin-right: 3px;
    filter: invert(54%) sepia(5%) saturate(457%) hue-rotate(324deg) brightness(96%) contrast(88%);
}

#export-hp-icon {
    width: 25px;
    filter: invert(54%) sepia(5%) saturate(457%) hue-rotate(324deg) brightness(96%) contrast(88%);
}