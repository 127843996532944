.nav-popup-basic {
    padding: 20px;
    padding-top: 0px;
    background-color: white;
    border: solid gray 1px;
    position: absolute;
    z-index: 10;
    right: 10%;
    top: 14%;
    overflow-y: auto;
    color: black;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: left;
    min-width: 250px;
}

#fin-hr {
    margin: 10px 0;
}

#fin-1 {
    margin-top: 15px;
}

#fin-head {
    margin-bottom: 10px;
}

.fin-2 {
    color: rgb(54, 54, 54);
    font-weight: 500;
    margin: 0;
    margin-top: 5px;
    padding: 0;
    font-size: 14px;
}

#fin-btn {
    margin-top: 8px;
}

#scenario-modifier-nav {
    margin-left: 10px;
    padding: 2px;
    height: fit-content;
    width: fit-content;
    margin-top: 8px;
}

.scenarios-top-div {
    padding: 0;
    margin: 0;
    margin-top: 8px;
}

.scenarios-top-txt {
    padding: 0;
    margin: 0;
    margin-right: 10px;
}

.scenarios-top-btn {
    text-decoration: underline;
    margin: 0;
    padding: 0;
    color: #c3890b;
    cursor: pointer;
}