.graph-container-outer {
    padding: 30px;
    padding-top: 20px;
    padding-bottom: 40px;
    border-radius: 5px;
    border-top-left-radius: 0;
    /* margin: 10px; */
    justify-content: center;
    background-color: white;
    /* overflow-y: scroll; */
    border: solid 1px #8b8280;
    max-height: 60vh;
}

.tooltip-div {
    margin: 0px; 
    padding: 15px; 
    padding-bottom: 5px;
    background-color: rgb(252, 252, 252); 
    border: 1px solid rgb(204, 204, 204); 
    white-space: nowrap;
    border-radius: 6px;
}

.tooltip-label {
    margin: 0;
    text-align: left;
    margin-bottom: 6px;
    font-weight: 500;
}

.graph-geo-txt {
    margin: 0px;
    padding: 0px;
    font-size: 15px;
    margin-bottom: 10px;
}

.graph-legend {
    display: flex;
    flex-direction: row;
    margin-left: 65px;
    margin-top: 10px;
}

.gl-right {
    justify-content: flex-end;
}

.graph-legend-item {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    font-family: "Roboto" sans-serif;

}

.graph-tooltip-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    font-family: "Roboto" sans-serif;
    margin-bottom: 5px;
}

#gene-tooltip-item {
    text-align: left;
    font-weight: 600;
    font-size: 11px;
    font-family: "Roboto" sans-serif;
    margin-bottom: 5px;
    margin-left: 13px;
}

.graph-legend-circle { 
    width: 7px;
    height: 7px;
    border-radius: 15px;
    margin-right: 5px;
    margin-bottom: 2px;
}

.graph-legend-circle-offscreen { 
    width: 5px;
    height: 5px;
    border-radius: 15px;
    margin-right: 5px;
    margin-bottom: 0px;
}

.graph-tooltip-text {
    margin-bottom: 2px;
}

#gene-tooltip-patients {

}

.graph-tooltip-text-e {
    margin: 0;
    margin-bottom: 4px;
    font-size: 14px;
    color: rgb(0, 0, 0) ;
    font-weight: 500;
    text-align: left;
}

.off-screen-rendering {
    position: absolute;
    left: -9999px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
}

.off-screen-chart {
    height: 353px;
    width: 600px;
}

#off-screen-text {
    text-align: center;
    font-family: arial;
    font-weight: 500;
}