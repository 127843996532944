


/*  */
.landing-page-master {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.landing-page-main {
    margin-top: 60px;
    background-color: #f4f4ef
}

/* NAV ELEMS */

.landing-page-nav {
    position: fixed;
    z-index: 10;
    background-color:#f4f4ef;
    border-bottom: solid rgba(81, 82, 83, 0.2) 1px;
    height: 60px;
    width: 100%;
    box-sizing: border-box;
    padding: 40px 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.landing-page-nav-sub  {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between; /* Adjusted to space-between to push children to edges */
    align-items: center; /* This will vertically center the items if needed */
    width: 100%;
    height: 100%;
    max-width: 1425px;
    margin: auto;
}

.lpn-left{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: left;
}
.lpn-right {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: right;
}

.lpn-title {
    margin-right: 40px;
    font-size: 32px;
    color: rgb(30, 27, 21);
}

.lpn-icon {
    width: 15px;
    margin-right: 5px;
}

.lpn-scrolls {
    color: #212529;
    opacity: 1;
    margin-left: 25px;
    font-size: 1rem;
}

.lpn-login {
    margin-right: 20px;
}

.lpn-login-txt {
    font-size: 15px;
}

.lpn-btn {
    color: rgba(255, 255, 255);
    background-color: #008CBA;
    border: none;
    padding: 5px 15px;
    margin-right: 15px;
    font-weight: 650;
    border-radius: 0.5rem;
}

.link-site-nav {
    width: 200px;
    height: 400px;
    border: solid #212529 1px;
}


.mobile-nav {
    display: none;
}

/* HEADER  */

.landing-page-header {
    background-color: #f4f4ef;

    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: solid rgba(33, 37, 41, .2) 1px;
    
    margin-bottom: 4rem;
    align-items: center;
    align-self: center;
    align-content: center;
    text-align: center;
    position: relative; /* Add this line */
    overflow: hidden; /* Add this line */
}

.header-wrp {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1585px;
    padding: 0 80px;
    padding-top: 8rem;
    padding-bottom: 4rem;
    box-sizing: border-box;
    position: relative; /* Add this line */
    overflow: hidden; /* Add this line */

}

.lpn-header {
    color: #FFFFFF;
    font-size: 3.6em;
    font-weight: 600;
    margin-bottom: 0px;
    max-width: 40rem;
    justify-self: center;
    /* line-height: 1.01; */
}

.lpn-features-header-wrp {
    width: 100%;
    padding-bottom: 3rem;
    border-bottom:solid rgba(33, 37, 41, .2) 1px;
}

.lpn-features-header {
    color: rgb(30, 35, 48);
    font-size: 3.6em;
    font-weight: 600;
    margin-bottom: 0px;
    max-width: 300rem;
    
}
/* .lpn-header {
    color: #FFFFFF;
    opacity: .9;
    font-size: 3.1em;
    font-weight: 500;
    margin-bottom: 0px;

} */

.lpn-subheader {
    color: #FFFFFF;
    font-weight: 300;
    font-size: 1.8em;
    opacity: .9;
    margin-top: .2rem;
    max-width: 60rem;
    padding-bottom: 4rem;
}

.lpn-features-subheader {
    text-align: center;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 1.8em;
    opacity: .9;
    margin-top: .2rem;
    max-width: 60rem;
    padding-bottom: 4rem;
}

.lpn-btn-big {
    font-size: 25px;
    font-weight: 500;
    padding: 15px 25px;
    margin-top: 15px;
}

.launch-btn-big {
    background-color: rgba(95, 95, 95, 0.5);
    border: solid rgba(95, 95, 95, 0.2) 1px;
    border-radius: 5px;
    color: #ffffffb1;
    font-size: 25px;
    font-weight: 500;
    padding: 14px 24px;
    margin-top: 15px;
}


.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.70); /* Adjust the color and opacity as needed */
    z-index: 1  ; /* Place it above the video but below other content */
}

.lpn-video {
    position: absolute;
    top: 1rem ;
    /* left: 0; */
    width: 80%; /* Cover the full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
    object-position: center; 
}

.lpn-buttons {
    padding-bottom: 2rem;
}

/* .lpn-video {
    width: 98%;  Cover the full width 
    max-width: 1424px;
    height: auto;  Maintain aspect ratio 
    object-fit: cover;
    object-position: center; 
    margin-bottom: 20px;
    border: solid white 4px;
    border-radius: 5px; 
} */

.lpn-header, .lpn-subheader, .lpn-btn-big {
    position: relative;
    z-index: 1;
}   


.lpn-features-summary {
    align-self: center;
}

.lpn-features-summary, .lpn-header, .lpn-subheader {
    color: rgb(30, 35, 48);
}

#main-info {
    letter-spacing: .01rem;
    color: #fffffff1 !important;
}

#pharma {
    color: #ffffff!important;
    
    
}
#div-city {
    color: #ffffff !important;
    opacity: .7;

}
.lpn-other-header {
    width: 100%;
    z-index: 1;
    color: #ffffff !important;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3.1em;
    font-weight: 500;
}

#up-fore-icon, #up-quick-icon, #up-prev-icon {
    width: 100% !important;
    height: 100% !important;
}


#switch {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
}
.skinny {

    display: inline;
    opacity: .7;
    font-weight: 400;
}

.small-column {
    width: 50px; /* Fixed width */
    height: 50px; /* Fixed height */
    background-color: #f0f0f0; /* Example styling */
    /* Prevent the box from growing */
    flex: 0 0 auto;
}

.large-column {
    flex-grow: 1;
    padding: 0 15px;
}

/* INFO  */

.landing-page-info {
    box-sizing: border-box;
    padding: 40px 80px;
    width: 100%;
    background-color: #f4f4ef;
    align-items: center;
    z-index: 3;
}
.lpn-info {
    display: grid;
    gap: 60px;
    /* min-height: 400px;
    max-height: 500px; */
    margin-bottom: 100px;
}

.lpn-l {
    grid-template-columns: 3fr 4fr;
    
    max-width: 1424px;
}
.lpn-r {
    grid-template-columns: 4fr 3fr;
    
    max-width: 1424px;
}

.lpn-img-placeholder {
    width: 100%;
    min-width: 100%;
    height: 100%;
    /* border: solid white 4px;
    border-radius: 5px; */
}

.lpn-img-size {
    max-width: 100%;    

    height: auto;
    border: solid white 4px;
}

.lpn-info-blurb {
    text-align: left;
}

.lpn-blurb-title {
    letter-spacing: -0.02em;
    line-height: 1.01em;
    margin: 0;
    color: rgb(30, 35, 48);
    font-weight: 800;
    opacity: .9;
    font-size: 45px;
}
/* 
#up-quick-icon {
    filter: invert(23%) sepia(56%) saturate(3552%) hue-rotate(354deg) brightness(83%) contrast(102%);
} */


.lpn-blurb-txt {
    font-size: 20px;
    line-height: 30px;
    font-weight: 380;
    color: rgb(30, 35, 48);
    opacity: .9;
}

.lpn-blurb-subtitle {
    font-size: 20px;
    line-height: 30px;
    font-weight: 380;
    color: rgb(30, 35, 48);
    opacity: .9;
    max-width: 288.44px;
}



/* features  */

.landing-page-features {
    width: 100%;
    color: rgb(30, 35, 48);
    max-width: 1424px;
    box-sizing: border-box;
    border-top: solid rgba(33, 37, 41, .2) 1px;
    border-bottom: solid rgba(33, 37, 41, .2) 1px;
    padding: 100px 80px;
    padding-bottom: 3rem; 
    padding-top: 3rem;
    margin-bottom: 100px;
    margin-top: 0;
    background-color: #f4f4ef;

    align-self: center;
}

.landing-page-features .lpn-header {
    margin-top: 0;
}
.lpn-header {
    width: 100%;
}



.lpn-no-marg {
    margin-bottom: 0;
}

.lpn-features-banc {
    margin: 0;
}

.lpn-features-items {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 1;
    align-self: center;
    justify-content: space-evenly;
    gap: 7rem;
}

.lpn-img-placeholder-circle {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    margin-bottom: 40px;
}

.lpn-features-item {
    align-items: center;
}


/* pricing */

.landing-page-pricing-wrp {

    align-items: center;
    padding: 120px;
    width: auto;
    max-width: 1424px;
    min-width: 80%;
    align-content: center;
    justify-content: center;
    align-self: center;
    
    
    
}

.landing-page-pricing {
    text-align: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;
    max-width: 1424px;
    width: 100%;
    padding: 50px 60px;
    /* margin: 80px; */
    box-sizing: border-box;
    background-color: #f4f4ef;
    margin: auto;
}

.lpn-pricing-box {
    text-align: left;
    color: white;
    background-color: rgb(95, 95, 95);
    width: 90%;
    box-sizing: border-box;
    padding: 5px 40px;
    justify-content: space-between;
    margin-bottom: 20px;
}

.lpn-btn-signup {
    margin-top: 20px;
    font-size: 25px;
    padding: 10px 60px;
}

.lpn-pricing-head {
    margin-top: 0;
    font-size: 35px;
    margin-bottom: 0;
}
.lpn-pricing-sub {
    font-size: 22px;
    margin-bottom: 30px;
    background-color: #f4f4ef;
    width: 100%;
}



.landing-page-closer {
    width: 100%;
    background-color: #f4f4ef;
}

.landing-page-closer {
    align-items: center;
    
}

.footer-title {
    font-size: 32px;
    color: rgb(30, 27, 21);
    padding-top: 3rem;
}

.disclaimer-wrp {
    text-align: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;
    max-width: 1424px;
    padding: 50px 60px;
    padding-top: 0;
    /* margin: 80px; */
    box-sizing: border-box;
    background-color: #f4f4ef;
    margin: auto;

}

.disclaimer {
  
    text-align: left;
    align-items: center;
    align-self: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;
    max-width: 1424px;
    width: 100%;
    padding: 50px 60px;
    padding-top: 1rem;
    padding-bottom: 0;
    /* margin: 80px; */
    box-sizing: border-box;
    background-color: #f4f4ef;
    margin: auto;
    opacity: .6;
    font-size: 12px;
    line-height: 1.5rem;
}

.legal-txt-wrp {
    
    text-align: center;
    justify-content: right;
    justify-self: center;
    justify-items: center;
    /* margin: 80px; */
    box-sizing: border-box;
    background-color: #f4f4ef;


}


.landing-page-footer {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    justify-content: space-evenly;
    max-width: 1424px;
    padding: 0px 120px;
    margin: auto;
    box-sizing: border-box;

}

.landing-page-footer .legal-txt {
    font-size: 15px;
    margin-top: 0em;
    margin-left: 80px;
    text-align: right;
}

.holder {
    width: auto;
}

.copyright-wrp {
    text-align: left;
    width: 50;
}

.legal-wrp {
    align-items: right;
    align-self: right;
    justify-content: right;
    justify-self: center;
    justify-items: center;

    text-align: right;
    width: auto;
    margin-left: auto;

}

.landing-page-copyright {
    width: 100%;
    opacity: .6;
    font-size: 15px;
    text-align: left;
}
@media only screen and (max-width: 768px)  {

    .header-wrp {
        padding: 0 20px;
    }

        /* This media query is specific to the device width shown in the screenshot */
      
        /* Hide all navigation items except for the logo and hamburger menu */
        .lpn-login, .lpn-btn {
            display: none; /* Hide all menu items */
        }
        .lpn-scrolls, #biglogin, #bigdemo {
            display: block;
        }

        .lpn-buttons {
            
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
        }
    
        /* Style the navigation bar to only include logo and hamburger icon */
        .landing-page-nav {
            padding: 10px 20px; /* Adjust padding */
            justify-content: space-between; /* Space between logo and hamburger */
        }
    
        /* Ensure the logo is properly sized and aligned */
        .lpn-left {
            justify-content: flex-start; /* Align logo to the left */
        }
    
        .lpn-title {
            font-size: 24px; /* Adjust logo text size */
        }
    
        

        /* Add hamburger icon */
        .hamburger-menu {
            display: block; /* Show the hamburger menu */
            width: 30px; /* Width of hamburger icon */
            height: 30px; /* Height of hamburger icon */
            /* background-image: url('/path/to/hamburger-icon.png'); Path to your icon */
            background-size: contain;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    
        .lpn-info {
            display: flex;
            gap: 60px;
            /* min-height: 400px;
            max-height: 500px; */
            margin-bottom: 100px;
        }
        
        .lpn-l {      
            display: flex; 
            flex-direction:column;     
            max-width: 1424px;
        }
        .lpn-r {
            display: flex; 
            flex-direction:column-reverse;
            max-width: 1424px;
        }

            
        .landing-page-features {
            padding: 0;
        }

        .landing-page-info {
            box-sizing: border-box;
            margin-top: 0;
            padding: 40px 10px;
            padding-top: 0;
        }


        .lpn-features-header {
            color: rgb(30, 35, 48);
            font-size: 2em;
            font-weight: 500;
            margin-bottom: 0px;
            max-width: 300rem;
            
        }
        .lpn-features-header-wrp {
            padding-bottom: 1rem;
            margin-bottom: 1.1rem;
        }

        .lpn-header {
            padding-top: 5rem;
            font-size: 3em;
        }
        .lpn-subheader {
            font-size: 1.2em;
            padding-bottom: 1em;
        }


        
    .lpn-video {
        margin-top: 2rem;
        width: 150%;
        left: -.1em;
    }

        
    .landing-page-pricing-wrp, .landing-page-pricing {
        padding-top: 0;
    }

    
    .lpn-pricing-box {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: white;
        background-color: rgb(95, 95, 95);
        width: 90%;
        box-sizing: border-box;
        padding: 5px 40px;
        justify-content: center;
        margin-bottom: 20px;
        width: auto;
    }

    .lpn-pricing-box-txt {
        
    }

    .landing-page-closer {
        width: 100%;
        background-color: #f4f4ef;
    }

    .landing-page-closer {
        align-items: center;
        
    }

    .footer-title {
        font-size: 32px;
        color: rgb(30, 27, 21);
        padding-top: 3rem;
    }

    .disclaimer-wrp {
        text-align: center;
        align-items: center;
        align-self: center;
        justify-content: center;
        justify-self: center;
        justify-items: center;
        padding-top: 0;
        /* margin: 80px; */
        box-sizing: border-box;
        background-color: #f4f4ef;
        margin: auto;

    }

    .disclaimer {
    
        text-align: left;
        align-items: center;
        align-self: center;
        justify-content: center;
        justify-self: center;
        justify-items: center;
        padding: 0;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 0;
        /* margin: 80px; */
        box-sizing: border-box;
        background-color: #f4f4ef;
        margin: auto;
        opacity: .6;
        font-size: 12px;
        line-height: 1.5rem;
    }

    .legal-txt-wrp {
        margin: auto;

        text-align: center;
        justify-content: center;
        justify-self: center;
        justify-items: center;
        /* margin: 80px; */
        box-sizing: border-box;
        background-color: #f4f4ef;


    }


    .landing-page-footer {
        width: 100%;
        min-width: 90%;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-self: center;
        justify-content: space-evenly;
        padding: 0;
        box-sizing: border-box;

    }

    .landing-page-footer .legal-txt {
        font-size: 15px;
        margin-left: 1em;
        margin-top: 0em;
        text-align: center;
        
    }

    .holder {
        width: auto;
    }

    .copyright-wrp {
        text-align: left;
        width: 50;
    }

    .legal-wrp {
        padding: 0;
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        justify-content: center;
        justify-self: center;
        justify-items: center;

        text-align: center !important;
        width: auto;
        

    }


    .landing-page-copyright {
        width: 100%;
        opacity: .6;
        font-size: 15px;
        text-align: center;
    }
}