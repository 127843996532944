.notepad-outer {
    position: absolute;
    z-index: 100;
    background-color: white;
    border: solid rgb(191, 191, 191) 1px;
    border-radius: 7px;
    /* width: fit-content;
    height: fit-content; */
}

#notepad-outer-positioning {
    right: 10%;
    top: 13%;
}


.react-resizable-handle-se {
    width: 24px;
    height: 24px;
    background-image: url( '../icons/bancroft_expand_note_icon.svg' );;
}

.react-resizable-handle-se:hover {
    filter: invert(98%) sepia(2%) saturate(9%) hue-rotate(29deg) brightness(102%) contrast(104%);
}

.note-tabs {
    display: flex;
    flex-direction: column;
    max-height: 75%;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 13px;
    align-items: flex-start;
    padding: 20px;
    text-align: left;
    word-wrap: break-word;
}

.note-tab {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    cursor: default;
    opacity: .6;
}

.note-tab:hover {
    opacity: 1;
    color: #f6921e;
}

#notepad-icon {
    width: 18px;
    margin-right: 3px;
}

#notepad-icon-x {
    width: 13px;
    margin-left: auto;
    margin-right: 3px;
    filter: invert(100%) sepia(94%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
}

#notepad-icon-x:hover {
    filter: none;
}

#first-icon-np {
    margin-right: 10px;
}

.add-note {
    padding: 0;
    margin: 0;
    margin-left: auto;
    margin-right: 10px;
    font-size: 20px;
}
.drag-handle {
    height: 12px;
    background-color: rgb(92, 92, 92);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: solid rgb(191, 191, 191) 1px;
}


.quill {
    height: 100%;
}

.quill-editor {
    height: calc(100% - 70px);
    max-height: calc(100% - 70px);
    width: 100%;
}

.inner-editor {
    height: 100%;
    width: 100%;
}

.ql-container {
    font-family: "Roboto" sans-serif !important;
    padding: 0 20px;
    padding-top: 60px;
    height: 100%;
}

.ql-container.ql-snow{ 
    border: none !important;
}

.ql-toolbar.ql-snow{ 
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
}

.ql-toolbar {
    position: fixed;
    z-index: 5;
    background-color: white;
    width: 100%;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    transform: translate(-70%, 0);
}
.ql-toolbar.ql-snow:first-child .ql-header .ql-picker-options {
    transform: translate(0,0);

}

.notes-options {
    position: absolute;
    bottom: 0;
    padding-left: 35px;
    padding-bottom: 5px;
}


/* #ql-picker-options-0 {
    transform: translate(0,0);
} */

.notepad-nav {
    background-color: #f6921e;
    color: white;
    height: 40px;
    padding: 0px 10px;
}

.notepad-icon-text {
    color: white;
    font-weight: 400;
    font-size: 12px;
    cursor: default;
}
.notepad-icon-text:hover {
    color: rgb(237, 237, 237);
}

#notepad-icon-y {
    width: 22px;
    margin-right: 7px;
    opacity: .6;
}
#notepad-icon-y:hover {
    opacity: 1;
}

.invalid-export {
    padding: 10px;
    text-align: left;
    border: solid 2px rgb(208, 54, 54);
    position: absolute;
    background-color: rgb(255, 255, 255);
    color: black;
    z-index: 10;
}