.user-page-master {
    background-color: #f4f4ef;
    height: 100%;
    overflow-y: auto;
    margin: auto;
}

.up-nav-div {
    padding: 0 50px;
}

.user-page-signout {
    margin-left: auto;
    cursor: pointer;
}
.user-page-signout:hover {
    color: rgb(105, 105, 105);
}

.user-page-signout-txt {
    margin-left: 5px;
}

.up-welcome-wagon {
    text-align: center;
    font-weight: 500;
    font-size: 50px;
    margin-bottom: 40px;
}

.up-main {
    display: flex;
    max-width: 1000px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    padding-bottom: 50px;
}

.up-links-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    margin-bottom: 45px;
}

.up-link-div {
    cursor: pointer;
    background-color: white;
    width: 300px;
    padding: 50px 0;
    border: solid 1px white;
}


.up-link-div:hover {
    border: solid 1px rgb(196, 196, 196);
    background-color: rgb(252, 252, 252);
}

.up-link-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#up-forecast-icon {
    width: 50px;
    filter: invert(53%) sepia(59%) saturate(425%) hue-rotate(157deg) brightness(82%) contrast(100%);

}
#up-qa-icon {
    width: 50px;
    filter: invert(23%) sepia(56%) saturate(3552%) hue-rotate(354deg) brightness(83%) contrast(102%);
}

#up-prevalence-icon {
    width: 50px;
    filter: invert(50%) sepia(14%) saturate(7491%) hue-rotate(21deg) brightness(102%) contrast(91%);
}

.up-link-txt {
    font-weight: 400;
    font-size: 27px;
}

.up-profile-details {
    border: solid 1px gray;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.up-details-banner {
    background-color: #3c8ab6;
}

.up-details-banner-txt {
    color: white;
    text-align: left;
    margin: 0;
    margin-left: 15px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    padding: 5px 0;
}

.up-details-expand {
    background-color: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 10px 0;
}

#up-expand-icon {
    cursor: pointer;
    filter: brightness(0) saturate(100%);
}

#up-expand-icon-down {
    cursor: pointer;
    filter: brightness(0) saturate(100%);
    transform: rotate(180deg);
}

#up-expand-icon:hover {
    filter: brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(23%) hue-rotate(18deg) brightness(91%) contrast(96%);
}
#up-expand-icon-down:hover {
    filter: brightness(0) saturate(100%) invert(48%) sepia(27%) saturate(23%) hue-rotate(18deg) brightness(91%) contrast(96%);
}
.up-details-main {
    background-color: white;
    padding: 0px 15px;
    width: 100%;
    box-sizing: border-box;
}

.up-details-login {
    width: 100%;
    box-sizing: border-box;
}

.up-dl-header {
    color: rgb(158, 158, 158);
    font-weight: 600;
}

.up-details-login-container {
    text-align: left;
}

#up-dl-num1 {
    width: 180px;
}

#up-dl-num2 {
    margin-left: 100px;
}

#up-dl-num3 {
    margin-left: auto;
    width: 190px;
}

.up-dl-txt {
    font-weight: 700;
}

.up-dl-txt-btn {
    text-align: left;
    color: #3c8ab6;
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
}

.up-dl-txt-btn:hover {
    font-weight: 700;
}

.up-hr {
    width: 100%;
    height: 2px;
    background-color: rgb(218, 218, 218);
    margin: 15px 0;
}

/*  */

@media only screen and (max-width: 768px)  {
    #up-dl-num2 {
        margin-left: 0px;
    }
    .up-dl-email {
        width: 137.02px;
        max-width: 137.02px;
        word-break: break-all;
    }    

}